<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        
        <div>
            <bread-crumbs :items='items' />
            <tabsComp :tabs="tabs" />
            <v-row class="mt-10 mainrow m-2">
        <v-col
          cols="12"
          md="10"
          sm="12">
          <form autocomplete="off">
          <v-row  style="display:none">
            <v-col cols="12" md="2" sm="12" style="display:none">
              <label>{{lang.start_date}}</label>
              <b-input-group>
                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12" style="display:none">
              <label>{{lang.end_date}}</label>
              <b-input-group>
                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <label>بحث داخل الجدول</label>
              <b-input-group>
                <b-form-input class="inborder" v-model="search" style="background:#efefef;" />
              </b-input-group>
            </v-col>
            
            <v-col cols="12" md="2" sm="12" style="display:none">
              <label></label>
              <b-input-group style="margin-top:-5px">
                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCurrentCards()">{{lang.search}}</b-button>
              </b-input-group>
            </v-col>
          </v-row>

        </form>
        </v-col>
        <v-col cols="12" md="12" sm="12" class="text-end">
          <b-button
              v-b-toggle.add_it
              class="btn-sm btn "
              @click="resetAllValues()"
              style="margin-top:9px;width:165px;background:transparent;border:none !important;margin-left:-7px !important;"
              id="multiply-button" 
            >
              <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_offer}}</div>
          </b-button>
        </v-col>
      </v-row>
        <v-row class="mainrow" style="margin-inline-end:50px !important;margin-inline-start:20px !important;margin-top:-30px;">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :search="search"
                    :items="tablerows"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    fixed-header
                >
                <template slot="body.prepend">  
                  <tr>
                    <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-top:1px solid #000 !important;" colspan="6">&#32;</td>
                    <td style="font-size:.1rem !important;background:#fff !important;border-inline :none !important;border-bottom :2px solid #fff !important;border-top:1px solid #000 !important;">&#32;</td>
                  </tr>
                </template>
                <template v-slot:item="row">
                    <tr>
                        <td style="width:100px;text-align:center;">{{ row.item.sdate }}</td>
                        <td style="width:100px;text-align:center;">{{ row.item.edate }}</td>
                        <td>{{ row.item.offer_name }}</td>
                        <td>{{ row.item.plate_number }}</td>
                        <td>{{ row.item.customer_name }}</td>
                        <td>{{ row.item.mobile }}</td>
                        <td
                            style="
                              cursor: pointer;
                              width: 85px;
                              border: 2px solid #bbb !important;
                              border-top: none !important; 
                              padding:3px;
                              text-align: center;
                            "
                            class="blackBack"
                          >
                            <div v-if="row.item.customerid != 0" @click="getItem(row.item.id)" v-b-toggle.add_it>
                                {{lang.view}}</div>
                        </td>
                    </tr>
                </template>
                </v-data-table>
            </v-col>
        </v-row>
        </div>
        <addOffer ref="addOffer" />
        <Footer />
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import tabsComp from '@/components/tabsComp.vue'
import ExportsPrint from '@/components/exports-print.vue';
import addOffer from '@/components/addOffer.vue'
import axios from 'axios'
export default{
    components: {BreadCrumbs,HeaderPortrate,Footer,tabsComp,ExportsPrint,addOffer},
    data(){
        return {
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear()
            },
            ed: {
                day: (new Date()).getDate(),
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear()
            },
            tablerows: [
                {
                    start_at: "2024-02-15",
                    end_at: "2025-02-15",
                    plate_number: "ا ب ت 1980",
                    offer_name: "عرض التميز",
                    customer_name: "محمد",
                    mobile: "0566566160",
                }
            ],
            search: '',
        }
    },
    computed:{
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
      sdate: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
      },
      edate: function() {
        return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
      },
      yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
          if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
          }
          
        }
        return t
      },
      daysList: function(){
        return [
          {text: this.lang.day, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
          {text: 13, value: 13},
          {text: 14, value: 14},
          {text: 15, value: 15},
          {text: 16, value: 16},
          {text: 17, value: 17},
          {text: 18, value: 18},
          {text: 19, value: 19},
          {text: 20, value: 20},
          {text: 21, value: 21},
          {text: 22, value: 22},
          {text: 23, value: 23},
          {text: 24, value: 24},
          {text: 25, value: 25},
          {text: 26, value: 26},
          {text: 27, value: 27},
          {text: 28, value: 28},
          {text: 29, value: 29},
          {text: 30, value: 30},
          {text: 31, value: 31},
        ]
      },
      monthsList: function(){
        return [
          {text: this.lang.chose_month, value: -1},
          {text: 1, value: 1},
          {text: 2, value: 2},
          {text: 3, value: 3},
          {text: 4, value: 4},
          {text: 5, value: 5},
          {text: 6, value: 6},
          {text: 7, value: 7},
          {text: 8, value: 8},
          {text: 9, value: 9},
          {text: 10, value: 10},
          {text: 11, value: 11},
          {text: 12, value: 12},
        ]
      },
      items:function() {
          return {
            text: this.lang.invoices,
            disabled: true,
            href: '/invoicelist/',
          }
      },
      tabs: function(){
        
        const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
           
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                // // console.log("value",license);
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                      if(alicense[value.perms[0]] || value.perms[0] == 'any')
                          t.push(value)
                    }
                }
            })
        
            return t;
      },
      headers: function(){
        let t=[]
        
        t.push({
              text: "تاريخ البدء",
              align: 'center',
              filterable: true,
              value: 'exp_date',
              sortable: false,
              class: 'pa-0 backBlack'
        })
        t.push({
              text: "تاريخ الانتهاء",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack',
        })
        t.push({
              text: "اسم العرض",
              align: 'center',
              filterable: true,
              value: 'mobile1',
              sortable: false,
              class: 'pa-0 backBlack',
        })
        t.push({
              text: "رقم اللوحة",
              align: 'center',
              filterable: true,
              value: 'mobile2',
              sortable: false,
              class: 'pa-0 backBlack',
        })
        t.push({
              text: "اسم العميل",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack',
        })
        t.push({
              text: "رقم الاتصال",
              align: 'center',
              filterable: true,
              value: 'mobile3',
              sortable: false,
              class: 'pa-0 backBlack',
        })
        
        t.push({
              text: "الاجراء",
              align: 'center',
              filterable: false,
              value: 'domain',
              sortable: false,
              class: 'pa-0 blackBack'
        })
        return t;
      }
    },
    methods: {
      getOffers(){
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        const path = this.$router.currentRoute.path;
        let post = new FormData();
        post.append('defBranch',localStorage.getItem('defBranch'));
        post.append('financeYear',localStorage.getItem('financeYear'));
        post.append('type','getSubscriber'); 
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        post.append("data[id]",0) 
        axios.post( 
            this.$SAMCOTEC.r_path, post
        ).then((response) => {
            if(response && response.data && response.data.results && response.data.results.data){
                this.tablerows = response.data.results.data
            }
        })
      },
        resetAllValues(){
          this.$refs.addOffer.resetAllValues();
        },
        getItem(id){
          this.$refs.addOffer.getOffers(id);
        },
        getCus(id){
            this.$refs.customerRef.customerid = id;
            this.$refs.customerRef.getCustomerInfo();
        },
        getClients(){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOldClients'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",1) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.tablerows = response.data.results.data
                }
            })
        }
    },
    created(){
        this.getOffers();
    }
}
</script>