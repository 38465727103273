<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_offer" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{lang.add_new}}</span>
                </div>
                <div @click="hide" id="hideAddCus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.campaign_title }}</label>
                    <b-form-input class="inborder" v-model="campaign.campaign_title" />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.campaign_price }}</label>
                    <b-form-input class="inborder" v-model="campaign.campaign_price" />
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.campaign_start }}</label>
                    <b-form-input type="date" class="inborder" v-model="campaign.campaign_start" />
                </v-col>
                <v-col cols="12" md="12" sm="12">
                    <label>{{ lang.service_list }}</label>
                    <v-row>
                        <v-col cols="12" md="6" v-for="(items,index) in serviceList" :key="index">
                            <div  style="display:flex;flex-direction:row;border:1px solid #000;padding:5px;">
                                <b-form-checkbox @change="addIt(serviceList[index].id)" />
                                <span style="padding-inline-start:5px;">{{ serviceList[index][`item_name${lang.langname}`] }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">
                    {{lang.add}}
                </b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data(){
        return{
            campaign: {
                id: 0,
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                services: []
            },
            serviceList: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        checkItems: function(){
            let t = [];
            console.log(this.serviceList);
            return t;
        }
    }, 
    created(){
        this.getItemService();
        // this.getStaticid('inDustrial');
        // this.getStaticid('customerSource');
    },
    methods: {
        addCustomer(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "addOffer");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[offer]", JSON.stringify(this.campaign));
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.$parent.getOffers();
                document.getElementById('hideAddCus').click();
            });
        },
        addIt(id){
            // console.log(this.serviceList);
            if(this.campaign.services.includes(id)){
                const x = this.services.indexOf(id)
                this.campaign.services.splice(x,1)
            }else{
                this.campaign.services.push(id);
            }
        },
        resetAllValues(){
            this.campaign = {
                id: 0,
                campaign_title: '',
                campaign_start: '',
                campaign_price: '',
                services: []
            }
        },
        getItem(id){
            this.getOffers(id)
        },
        getOffers(id){
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            const path = this.$router.currentRoute.path;
            let post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getOffer'); 
            post.append('auth',cook);
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append("data[id]",id) 
            axios.post( 
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data){
                    this.campaign = response.data.results.data[0]
                }
            })
        },
        getStaticid(id) {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getStaticOptions");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        getItemService() {
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type", "getItemService");
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.serviceList = response.data.results.data
            });
        },
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
</style>